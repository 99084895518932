export const languages = [
  {
    name: "bosnia",
    data: {
      termsOfUse: "Uslovi korišćenja jedan red",
      titleTerms: "Pravila korištenja",
      agree: "Slazem se sa uslovima koriscenja",
      messageCancel: "Jeste li sigurni da želite izaći iz aplikacije?",
      leave: "napusti",
      givePassword: "Potrebna mi je privremena lozinka",
      havePassword: "Imam privremenu lozinku",
      sendMail: "Pošaljite email za registraciju",
      registrationAddress:
        "Ako želite da se registrujete sa postojećom adresom, pritisnite dugme ispod.",
      readTerms: "Prethodno pročitajte uslove korišćenja.",
      noUser: "Za navedenu adresu nema registrovanog korisnika.",
      newAddress: "Unesite novu adresu ili se registrirajte sa ovom adresom.",
      userLoginText:
        "Postovani,klikom na taster ispod definisacete svoju licnu lozinku sa kojom se dalje prijavljujete (Chapter X). Molimo vas da iskoristite i opciju dodatne zastite definisanjem PIN-a tasterom za kreiranje sablona,radi pristupa naloga u slucaju gubljenja lozinke.",
      newUser:
        "Za navedenu mail adresu ne postoji registrovani korisnik.Upisite tacnu mail adresu ili posaljite pozivnicu klikom na taster dole",
    },
  },
  {
    name: "croatia",
    data: {
      termsOfUse: "Uvjeti korištenja jedan red",
      titleTerms: "Uvjeti korištenja",
      agree: "Slažem se s uvjetima korištenja",
      messageCancel: "Jeste li sigurni da želite izaći iz aplikacije?",
      leave: "napustiti",
      givePassword: "Trebam privremenu lozinku",
      havePassword: "Imam privremenu lozinku",
      sendMail: "Pošaljite e-mail za registraciju",
      registrationAddress:
        "Ako se želite registrirati s postojećom adresom, pritisnite gumb ispod.",
      readTerms: "Prethodno pročitajte uvjete korištenja.",
      noUser: "Za navedenu adresu nema registriranog korisnika.",
      newAddress: "Unesite novu adresu ili se registrirajte s ovom adresom.",
      userLoginText:
        "Poštovani, klikom na donji gumb definirat ćete svoju osobnu lozinku kojom se dalje prijavljujete (poglavlje X). Također koristite opciju dodatne zaštite definiranjem PIN-a s gumbom za izradu predloška, ​​kako biste pristupili računu u slučaju gubitka lozinke.",
      newUser:
        "Nema registriranog korisnika za navedenu adresu e-pošte. Unesite ispravnu adresu e-pošte ili pošaljite pozivnicu klikom na gumb ispod.",
    },
  },
  {
    name: "montenegro",
    data: {
      termsOfUse: "Usloovi za kooriistenje jeedna liiniija",
      titleTerms: "Usloovi za kooriiscenje",
      agree: "Slaazeem se sa uslooviima kooriisceenja",
      messageCancel: "Jeste li sigurni da želite izaći iz aplikacije?",
      leave: "napusti",
      givePassword: "Potrebna mi e privremena lozinka",
      havePassword: "Imam privremenu lozinku",
      sendMail: "Posalji registracioni mail",
      registrationAddress:
        " Ukoliko zelite da se registrujete sa postojecom adresom pritisnite taster ispod.",
      readTerms: "Prethodno procitajte uslove koriscenja.",
      noUser: "Za navedenu adresu ne postoji registrovani korisnik",
      newAddress: "Upisite novu adresu ili se registrujte sa ovom adresom.",
      userLoginText:
        " Postovani,klikom na taster ispod definisacete svoju licnu lozinku sa kojom se dalje prijavljujete (Chapter X). Molimo vas da iskoristite i opciju dodatne zastite definisanjem PIN-a tasterom za kreiranje sablona,radi pristupa naloga u slucaju gubljenja lozinke.",
      newUser:
        "Za navedenu mail adresu ne postoji registrovani korisnik.Upisite tacnu mail adresu ili posaljite pozivnicu klikom na taster dole",
    },
  },
  {
    name: "macedonia",
    data: {
      termsOfUse: "Uslovi za koristenje edna linija",
      titleTerms: "Uslovi za koristenje",
      agree: "Se soglasuvam so uslovite za koristenje",
      messageCancel:
        "Dali ste sigurni deka sakate da izlezete od aplikacijata?",
      leave: "ostavi",
      givePassword: "Potrebna mi je privremena lozinka",
      havePassword: "Imam privremena lozinka",
      sendMail: "Ispratete e-pošta za registracija",
      registrationAddress:
        "Ako sakate da se registrirate so postoečka adresa, pritisnete go kopčeto podolu.",
      readTerms: "Pročitajte gi uslovite za koristenje odnapred",
      noUser: "Nema registriran korisnik za navedenata adresa.",
      newAddress: "Vnesete nova adresa ili registrirajte se so ovaa adresa.",
      userLoginText:
        "Počituvani, so kliknuvanje na kopčeto podolu, ḱe ja definirate vašata lična lozinka so koja prodolžuvate da se najavuvate (Poglavje X). Iskoristete ja i opcijata za dopolnitelna zaštita so definiranje na PIN so kopčeto za kreiranje šablon, za da pristapite do smetkata vo slučaj na gubenje na lozinkata.",
      newUser:
        "Nema registriran korisnik za navedenata adresa na e-pošta. Vnesete ja točnata adresa na e-pošta ili ispratete pokana so kliknuvanje na kopčeto podolu.",
    },
  },
  {
    name: "serbia",
    data: {
      termsOfUse: "Uslovi koriscenja jedan red",
      titleTerms: "Uslovi koriscenja",
      agree: "Slazem se sa uslovima koriscenja",
      messageCancel: "Da li ste sigurni da zelite da napustite aplikaciju?",
      leave: "napusti",
      givePassword: " Zahtevam privremenu lozinku",
      havePassword: "Imam privremenu lozinku",
      sendMail: "Posaljite registracioni mail",
      registrationAddress:
        " Ukoliko zelite da se registrujete sa postojecom adresom pritisnite taster ispod.",
      readTerms: "Prethodno procitajte uslove koriscenja.",
      noUser: "Za navedenu adresu ne postoji registrovani korisnik",
      newAddress: "Upisite novu adresu ili se registrujte sa ovom adresom.",
      userLoginText:
        "Postovani,klikom na taster ispod definisacete svoju licnu lozinku sa kojom se dalje prijavljujete (Chapter X). Molimo vas da iskoristite i opciju dodatne zastite definisanjem PIN-a tasterom za kreiranje sablona,radi pristupa naloga u slucaju gubljenja lozinke.",
      newUser:
        "Za navedenu mail adresu ne postoji registrovani korisnik.Upisite tacnu mail adresu ili posaljite pozivnicu klikom na taster dole",
    },
  },
  {
    name: "uk",
    data: {
      termsOfUse: "Terms of use one line",
      titleTerms: "Terms of use",
      agree: "I agree to the terms of use",
      messageCancel: "Are you sure you want to exit the application?",
      leave: "leave",
      givePassword: "I require a temporary password",
      havePassword: "I have a temporary password",
      sendMail: "Send a registration email",
      registrationAddress:
        "If you want to register with an existing address, press the button below.",
      readTerms: "Read the terms of use beforehand.",
      noUser: "There is no registered user for the specified address.",
      newAddress: "Enter a new address or register with this address.",
      userLoginText:
        "Dear Sir/Madam, by clicking on the button below, you will define your personal password with which you continue to log in (Chapter X). Please also use the option of additional protection by defining a PIN with the button to create a template, in order to access the account in case of losing the password.",
      newUser:
        "There is no registered user for the specified email address. Enter the correct email address or send an invitation by clicking the button below.",
    },
  },
];
