import { useEffect, useRef, useState } from "react";
import SingleMessage from "./SingleMessage";
import { useGlobalContext } from "../Context/Context";
import "./message.css";
import axios from "axios";

const Message = () => {
  const [ currentChat, setCurrentChat ]= useState();
  const { baseUrl,setLoggedUser,setApiKey } = useGlobalContext();
  const resetChat = useRef();


  function paramsToObject(entries) {
  
    
    const result = {}
    for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }

  useEffect(() => {

    const handleBackMessage = async () => {
      try {
        // Store the current scroll position

        const queryString = window.location.search;
        /* const urlParams = new URLSearchParams(queryString);
        console.log(urlParams);
        const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
        console.log(entries);
        const params = paramsToObject(entries);*/

        const arrayParams = queryString.substring(1).split("-");
        let params = {};
        arrayParams.forEach((child) => {
          const oneParametar = child.split("=");
          console.log(oneParametar);
          return (params[oneParametar[0]] = oneParametar[1]);
        });

        console.log(params);
        console.log(queryString);

        const dataApiKey = await axios.post(`${baseUrl}/chat/getApiKey.php`, {
          external_user_id: parseInt(params.external_user_id),
          chat_id: parseInt(params.chat_id),
        });

        console.log(dataApiKey);

        const dataEmailKey = await axios.post(
          `${baseUrl}/chat/getExternalUserMailByUserId.php`,
          {
            external_user_id: parseInt(params.external_user_id),
            api_key: dataApiKey.data.api_key,
          }
        );

        console.log(dataEmailKey);

        if (dataEmailKey.status === 200) {
          const mailUser = {
            id: parseInt(params.external_user_id),
            user_email: dataEmailKey.data.external_user_email,
            user_pin: "",
            isTemp: false,
            pattern: "",
            privilage: "",
            firstContact: "",
            secondContact: "",
          };

          setLoggedUser(mailUser);
          setApiKey(dataApiKey.data.api_key);
        }

        if (dataApiKey.data.status === 200 && dataEmailKey.status === 200) {
          const { data } = await axios.post(
            `${baseUrl}/chat/getAllMessagesByChatId.php`,
            {
              external_user_id: parseInt(params.external_user_id),
              chat_room_id: parseInt(params.chat_id),
              resetChat: resetChat.current,
              api_key: dataApiKey.data.api_key,
            }
          );
          console.log(data);

          if (data.status === 200) {
            setCurrentChat(data);

            resetChat.current = false;
            // Restore the scroll position
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    
    resetChat.current = true
    // Load messages instantly
    handleBackMessage();
  

   const interval = setInterval(handleBackMessage, 10000);
  }, []);

  return (
    <div className="left-side-message">
      <SingleMessage
        currentChat={currentChat}
        setCurrentChat={setCurrentChat}
        isFullVisible={true}
      />
    </div>
  );
};

export default Message;
