import "./singleMessage.css";
import { format } from "date-fns";
import Button from "../Button/Button";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import ReplyIcon from "@mui/icons-material/Reply";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RedoIcon from "@mui/icons-material/Redo";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState, useRef } from "react";
import { useGlobalContext } from "../Context/Context";
import BackCheck from "../picture/Back.png";
import ForwardMessage from "./ForwardMessage";

const SingleMessage = ({
  currentChat,
  setCurrentChat,
  isFullVisible,
}) => {


  const { setActiveIconsMiddle, activeIconsMiddle } = useGlobalContext();
  const { loggedUser, apiKey } = useGlobalContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedMessages, setSearchMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [localChat, setLocalChat] = useState(null);
  const { baseUrl } = useGlobalContext();
  const [selectedMessage, setSelectedMessage] = useState({
    value: false,
    data: "",
  });
  const [isReply, setIsReply] = useState(false);
  const [isForward, setIsForward] = useState(false);
  const messageEndRef = useRef(null);

console.log(currentChat)
console.log(localChat)

  const handleSearch = (isUp) => {
    if (searchTerm === "") return;
    if (searchedMessages.length === 0) {
      const newMessages = localChat.messages.filter((msg) =>
        msg.text.includes(searchTerm)
      );
      setSearchMessages(newMessages);
      setCurrentMessage(0);
    } else {
      if (isUp) {
        setCurrentMessage(
          currentMessage === searchedMessages.length - 1
            ? 0
            : currentMessage + 1
        );
      } else {
        setCurrentMessage(
          currentMessage === 0
            ? searchedMessages.length - 1
            : currentMessage - 1
        );
      }
    }
  };

  const handleChangeTerm = (e) => {
    setSearchTerm(e.target.value);
    setSearchMessages([]);
  };

  const setClassNameCheck = (id, sender_id) => {
    if (loggedUser.id === sender_id) {
      return id === selectedMessage.data?.id
        ? "message current checked"
        : "message current";
    } else {
      return id === selectedMessage.data?.id
        ? "message other checked"
        : "message other";
    }
  };

  const setClassNameSelect = (id, sender_id) => {
    if (loggedUser.id === sender_id) {
      return id === searchedMessages[currentMessage]?.id
        ? "message current selected"
        : "message current";
    } else {
      return id === searchedMessages[currentMessage]?.id
        ? "message other selected"
        : "message other";
    }
  };

  const handleCopy = async (message) => {
    try {
      await navigator.clipboard.writeText(message);
      setSelectedMessage({ value: false, data: "" });
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSelectMessage = (message) => {
    setSelectedMessage({ value: true, data: message });
  };

  const handleCloseSelect = () => {
    setSelectedMessage({ value: false, data: "" });
  };

  const handleSendMessage = async () => {
    console.log(currentChat)
    const message =
      document.querySelectorAll(".message-input.msg")[isFullVisible ? 0 : 1];
      console.log("Ovo je poruka",message.value)
    if (message.value === "") return;
    try {
      let newMessage = {
        id: Date.now(), // Assign a unique ID temporarily for rendering
        sender_id: loggedUser.id,
        sender: loggedUser.user_email,
        text: message.value,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        reply: "",
      };
      console.log(apiKey)
      console.log(format(new Date(), "dd.MM.yyyy HH:mm:ss"))
      const { data } = await axios.post(`${baseUrl}/chat/sendMessage.php`, {
        sender: loggedUser.user_email,
        text: message.value,
        date: format(new Date(), "dd.MM.yyyy HH:mm"),
        api_key: apiKey,
        chat_room_id: parseInt(currentChat.chat_room_id),
      });
        console.log("Poruka je uspesno upisana",data)
      let messageId = data.message_id;
      if (isReply) {
        setIsReply(false);
        const { data } = await axios.post(`${baseUrl}/chat/sendReply.php`, {
          user_id: parseInt(loggedUser.id),
          message_id: parseInt(messageId),
          reply_text: selectedMessage.data.text,
          api_key: apiKey,
        });
        if (data.status === 200) {
          newMessage = {
            ...newMessage,
            reply: {
              sender: selectedMessage.data.sender,
              text: selectedMessage.data.text,
            },
          };
          setSelectedMessage({ value: false, data: "" });
          message.value = "";
        }
      }
      
      const newMessages = [...localChat.messages, newMessage];
      setLocalChat({ ...localChat, messages: newMessages });
      if (currentChat.id === localChat.id)
        setCurrentChat({ ...currentChat, messages: newMessages });
      message.value = "";
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchedMessages.length !== 0) {
      let containers = document.querySelectorAll(".left-side-message-wrapper");
      let container;
      if (containers[1] === undefined && !isFullVisible)
        container = containers[0];
      else if (!isFullVisible) container = containers[1];
      else container = containers[0];
      container
        ?.querySelector(`#\\3${searchedMessages[currentMessage].id}`)
        ?.scrollIntoView();
    }
  }, [currentMessage]);

  useEffect(() => {
    if (isFullVisible) setLocalChat(currentChat);
  }, [currentChat]);

  useEffect(() => {
    if (localChat !== null) {
      let containers = document.querySelectorAll(".left-side-message-wrapper");
      let container;
      if (!isFullVisible) container = containers[1];
      else container = containers[0];
      container
        ?.querySelector(`[id='${localChat?.messages.length}']`)
        ?.scrollIntoView();
    }
  }, [localChat]);

  // Scroll to bottom when a new message is added
  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [localChat?.messages?.length]);

  // Scroll to current search result
  useEffect(() => {
    if (searchedMessages.length > 0 && currentMessage !== "") {
      const currentMsg = document.getElementById(
        searchedMessages[currentMessage].id
      );
      if (currentMsg) {
        currentMsg.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [currentMessage, searchedMessages]);


  return (
    <>
      <div className="left-side-message-wrapper">
        <div
          className={`${
            selectedMessage.value ? `message-header blocked` : `message-header`
          } `}
        >
          <div className="btn-up" onClick={() => handleSearch(false)}>
            <Button activation={true}>
              <KeyboardArrowUpIcon />
            </Button>
          </div>
          <input
            className={`${
              selectedMessage.value ? `message-input blocked` : `message-input`
            } `}
            placeholder="Search..."
            onChange={handleChangeTerm}
          />
          <div className="btn-down" onClick={() => handleSearch(true)}>
            <Button activation={true}>
              <KeyboardArrowDownIcon />
            </Button>
          </div>
        </div>
        <div className="message-chat">
          {localChat !== null && localChat !== undefined &&
            localChat?.messages.map((msg, index) => {
              const { id, sender_id, sender, text, date } = msg;
              console.log(localChat)
              return (
                <article
                  id={id}
                  className={
                    selectedMessage.value
                      ? setClassNameCheck(id, sender_id)
                      : setClassNameSelect(id, sender_id)
                  }
                  key={index}
                  onDoubleClick={() => handleSelectMessage(msg)}
                >
                  {msg.reply !== "" && (
                    <div className="msg-reply">
                      <ReplyIcon />
                      <div className="msg-reply-information">
                        <span>{msg.reply.sender}</span>
                        <span>{msg.reply.text}</span>
                      </div>
                    </div>
                  )}
                  <span className="msg-data">{sender}</span>
                  <span className="msg-text">{text}</span>
                  <span className="msg-data">{date}</span>
                </article>
              );
            })}
          <div ref={messageEndRef} />
        </div>
        <div className="message-footer">
          {selectedMessage.value ? (
            <>
              {isReply ? (
                <>
                  <div className="message-reply">
                    <div className="message-reply-information">
                      <div className="message-sender">
                        {selectedMessage.data.sender}
                      </div>
                      <div className="message-text">
                        {selectedMessage.data.text}
                      </div>
                    </div>
                    <div
                      className="msg-select-button"
                      onClick={() => setIsReply(false)}
                    >
                      <Button activation={true}>
                        <CloseIcon />
                      </Button>
                    </div>
                  </div>
                  <div className="reply-input-container">
                    <input className="message-input msg" />
                    <div className="btn-send" onClick={handleSendMessage}>
                      <Button activation={true}>
                        <SendIcon />
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="msg-footer-select-left">
                    <div
                      className="msg-select-button"
                      onClick={handleCloseSelect}
                    >
                      <Button activation={true}>
                        <CloseIcon />
                      </Button>
                    </div>
                    <span>1 selected</span>
                  </div>
                  <div className="msg-footer-select-right">
                    <div
                      className="msg-select-button"
                      onClick={() => setIsReply(true)}
                    >
                      <Button activation={true}>
                        <ReplyIcon />
                      </Button>
                    </div>
                    <div
                      className="msg-select-button"
                      onClick={() => handleCopy(selectedMessage.data.text)}
                    >
                      <Button activation={true}>
                        <ContentCopyIcon />
                      </Button>
                    </div>
                    <div
                      className="msg-select-button"
                      onClick={() => setIsForward(true)}
                    >
                      <Button activation={true}>
                        <RedoIcon />
                      </Button>
                    </div>
                  </div>{" "}
                </>
              )}
            </>
          ) : (
            <>
              <input className="message-input msg" />
              <div className="btn-send" onClick={handleSendMessage}>
                <Button activation={true}>
                  <SendIcon />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      {isForward && (
        <ForwardMessage
          setSelectedMessage={setSelectedMessage}
          setIsForward={setIsForward}
          selectedMessage={selectedMessage}
          isFullVisible={isFullVisible}
          localChat={localChat}
          setLocalChat={setLocalChat}
        />
      )}
    </>
  );
};

export default SingleMessage;
