import React, { useContext, useState } from "react";
import { languages } from "../Vocabulary/Vocabulary";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[5]);
  const [account, setAccount] = useState();
  const [loggedUser, setLoggedUser] = useState(undefined);
  const [baseUrl] = useState("https://data.nadzor.app/api");
  const [apiKey, setApiKey] = useState("");
  const [currentChat, setCurrentChat] = useState(null);
  const [entryKey, setEntryKey] = useState("");
  const [location, setLocation] = useState();
  const [activeIconsMiddle, setActiveIconsMiddle] = useState([]);
  const values = {
    selectedLanguage,
    setSelectedLanguage,
    loggedUser,
    setLoggedUser,
    baseUrl,
    account,
    setAccount,
    apiKey,
    setApiKey,
    location,
    setLocation,
    entryKey,
    setEntryKey,
    currentChat,
    setCurrentChat,
    activeIconsMiddle,
    setActiveIconsMiddle,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
