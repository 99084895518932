import "./app.css";
import Message from "./Chat/Message";

const App = () => {
  return (
    <div className="app">
      <Message />
    </div>
  );
};

export default App;
